import React from "react";
import { Loading } from '../shared/constants/texts.js';


export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Nossos Serviços</h2>
          <p>
            Nossos serviços são essenciais para atender às diversas necessidades de nossos clientes, a fim de apoiá-los em sua jornada digital.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-4">
                {" "}
                <i className={d.icon}></i>
                <div className="service-desc">
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                </div>
              </div>
            ))
            : Loading}
        </div>
      </div>
    </div>
  );
};
